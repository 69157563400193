import { useQuery } from "@tanstack/vue-query";
import { isAxiosError } from "axios";
import { nanoid } from "nanoid";
import { getFullChatById, getMessages, sendImage, sendMessage , sendAudio } from "~/services/whatsapp";
import { toastApiError } from "~/shared/helpers/toastApiError.helper";

export const useWhatsappChatV1 = defineStore(
  "whatsapp-chat-v1",
  () => {
    const chats = ref<Array<any>>([]);
    const authStore = useAuthStore()
    const isSendingMessage = ref(false);
    const toast = useToast();
    const isWhatsAppModalOpen = ref(false);
    const getActiveChat = ref<any>({});
    const unreadSet = ref(new Set());
    const isConnected = ref<boolean>(false);
    const isConnectionModalOpen = ref<boolean>(false);

    const axiosV2 = useAxios({ useV2: true });
    const activeRetailChainStore = useActiveRetailChainAndPosStore();
    const userStore = useUserStore();

    const salesmanChats = ref([])
    const salesmanActiveChat = ref({})

    const canUserSendMessage = computed(() => {
      if (getActiveChat.value?.user?.uuid === userStore.user.uuid) {
        return false;
      }

      return true;
    });

    async function getInitialChats() {
      if (!activeRetailChainStore.pos) return null;
      const uuid = (activeRetailChainStore.pos as { uuid: string }).uuid;
      try {
        axiosV2.defaults.headers.common.Authorization = `Bearer ${authStore.access_token}`;
        const { data } = await getMessages(axiosV2, uuid);
        chats.value = data.data.chats;
        chats.value
          .filter((c) => c.unread)
          .map((c) => {
            if (unreadSet.value.has(c.id)) return;

            triggerNotification(c.name, c.messages[0]?.message);

            return unreadSet.value.add(c.id);
          });

        return data.data.chats;
      } catch (error) {
        console.log(error);
      }
    }

    async function getFullChat(uuid: string) {
      const chat = chats.value.find((c) => c.uuid === uuid);
      try {
        unreadSet.value.delete(chat.id);
        const { data } = await getFullChatById(axiosV2, chat.id);
        setActiveChat(data.data.chat);

        return data.data.chat;
      } catch (error) {
        console.error(error);

        if (isAxiosError(error)) {
          toast.add({
            title: "Erro",
            description: error.response?.data?.message,
          });
        }
      }
    }

    async function getFullChatByChatId(id: string) {
      const chat = chats.value.find((c) => c.id === id);
      try {
        unreadSet.value.delete(chat.id);
        const { data } = await getFullChatById(axiosV2, chat.id);
        setActiveChat(data.data.chat);

        return data.data.chat;
      } catch (error) {
        console.error(error);

        if (isAxiosError(error)) {
          toast.add({
            title: "Erro",
            description: error.response?.data?.message,
          });
        }
      }
    }

    async function getChatByLeadPage(id: string) {
      try {
        const { data } = await getFullChatById(axiosV2, id);
        setActiveChat(data.data.chat);
      } catch (error) {
        console.error(error);

        toastApiError(error);
      }
    }

    function setActiveChat(chat: any) {
      getActiveChat.value = chat;
    }

    async function handleSendMessage(input: string) {
      if (!activeRetailChainStore.pos) return;
      const uuid = (activeRetailChainStore.pos as { uuid: string }).uuid;

      try {
        isSendingMessage.value = true;
        if (uuid) {
          const { data } = await sendMessage(axiosV2, {
            message: input,
            phone: getActiveChat.value.phone,
            pos: uuid,
          });

          getActiveChat.value = data.data.chat;
        }
      } catch (error) {
        if (isAxiosError(error)) {
          toast.add({
            title: "Erro",
            description: error.response?.data?.message,
          });
        }
      } finally {
        isSendingMessage.value = false;
      }
    }

    const isChatHasNewNotifications = computed(() => {
      return chats.value.some((c) => c.unread);
    });

    onMounted(() => {
      chats.value = [];
      getActiveChat.value = {};

      getInitialChats();
    });

    const {
      isRefetching: isWhatsAppGetRefetching,
      isLoading: isWhatsAppGetLoading,
      ...whatsAppInitialLoadQuery
    } = useQuery({
      queryKey: ["whatsapp-first-load"],
      queryFn: async () => {
        if (!activeRetailChainStore.pos?.uuid) return [];
        return await getInitialChats().then((data) => data);
      },
      staleTime: 10000,
      refetchInterval: 10000,
    });

    const { isLoading: isChatLoading } = useQuery({
      queryKey: [
        "whatsapp-get-fullchat",
        getActiveChat.value?.uuid ?? nanoid(),
      ],
      queryFn: async () => {
        if (!getActiveChat.value.uuid) return {};

        const chat = await getFullChat(getActiveChat.value.uuid);

        return chat;
      },
      refetchInterval: 5000,
    });

    const triggerNotification = (username: string, content: string) => {
      toast.add({
        title: `Nova Mensagem de ${username}`,
        description: content || "",
      });
    };

    async function getFullChatWhenManager(uuid: string) {
      const chat = salesmanChats.value.find((c) => c.uuid === uuid);
      try {
        const { data } = await getFullChatById(axiosV2, chat.id);
        setActiveChat(data.data.chat);

        return data.data.chat;
      } catch (error) {
        console.error(error);

        toastApiError(error)
      }
    }

    async function handleSendUpload(input: { image: string, caption:string}) {
      if (!activeRetailChainStore.pos) return;
      const uuid = (activeRetailChainStore.pos as { uuid: string }).uuid;

      try {
        isSendingMessage.value = true;
        if (uuid) {
          const { data } = await sendImage(axiosV2, {
            phone: getActiveChat.value.phone,
            pos: uuid,
            image: input.image,
            caption: input.caption
          });

          getActiveChat.value = data.data.chat;
        }
      } catch (error) {
        if (isAxiosError(error)) {
          toast.add({
            title: "Erro",
            description: error.response?.data?.message,
          });
        }
      } finally {
        isSendingMessage.value = false;
      }
    }

    async function handleSendAudio (input: { audio: string }) {
      if (!activeRetailChainStore.pos) return;
      const uuid = (activeRetailChainStore.pos as { uuid: string }).uuid;


      try {
        isSendingMessage.value = true;
        if (uuid) {
          const { data } = await sendAudio(axiosV2, {
            phone: getActiveChat.value.phone,
            pos: uuid,
            audio: input
          });

          getActiveChat.value = data.data.chat;
        }
      } catch (error) {
        if (isAxiosError(error)) {
          toast.add({
            title: "Erro",
            description: error.response?.data?.message,
          });
        }
      } finally {
        isSendingMessage.value = false;
      }
    }

    return {
      isConnectionModalOpen,
      isConnected,
      isSendingMessage,
      isChatLoading,
      getInitialChats,
      chats,
      getActiveChat,
      setActiveChat,
      getFullChat,
      handleSendMessage,
      isChatHasNewNotifications,
      isWhatsAppModalOpen,
      whatsAppInitialLoadQuery,
      isWhatsAppGetLoading,
      isWhatsAppGetRefetching,
      getFullChatByChatId,
      getChatByLeadPage,
      canUserSendMessage,
      salesmanActiveChat, salesmanChats,
      getFullChatWhenManager,
      handleSendUpload,
      handleSendAudio
    };
  },
  {
    persist: {
      storage: persistedState.sessionStorage,
    },
  },
);
